let createPostSolicitudConsumoNueva =
  ({ fetch, environment, buildRequestWithAuth, errorUnlessOk, parseError }) =>
  (data) => {
    let request = buildRequestWithAuth("POST", data);

    return fetch(
      `${environment.originacionUrl}/consumo/solicitudes/nueva`,
      request
    )
      .then(errorUnlessOk)
      .then((response) => {
        return response.json();
      })
      .then((json) => {
        if (typeof json.id === "undefined" || isNaN(json.id))
          throw new Error("La solicitud no fue creada correctamente");

        return json;
      })
      .catch((error) => {
        let resultError = parseError(error, "createPostSolicitudConsumoNueva");
        console.error(resultError);
        throw resultError;
      });
  };

let createPostSolicitudConsumoDomicilio =
  ({ fetch, environment, buildRequestWithAuth, errorUnlessOk, parseError }) =>
  (data) => {
    let request = buildRequestWithAuth("POST", data);

    return fetch(
      `${environment.originacionUrl}/consumo/solicitudes/${data.Id}/domicilio`,
      request
    )
      .then(errorUnlessOk)
      .then((response) => {
        return response;
      })
      .catch((error) => {
        let resultError = parseError(error, "createPostSolicitudDomicilio");
        console.error(resultError);
        throw resultError;
      });
  };

let createPostDictamenConsumo =
  ({ fetch, environment, buildRequestWithAuth, errorUnlessOk, parseError }) =>
  (data) => {
    let request = buildRequestWithAuth("POST", data);

    return fetch(
      `${environment.originacionUrl}/consumo/solicitudes/${data.Id}/dictamen`,
      request
    )
      .then(errorUnlessOk)
      .then((response) => {
        return response.json();
      })
      .catch((error) => {
        let resultError = parseError(error, "createPostDictamenConsumo");
        console.error(resultError);
        throw resultError;
      });
  };

let createGetOfertasConsumo =
  ({ fetch, environment, buildRequestWithAuth, errorUnlessOk, parseError }) =>
  (data) => {
    let request = buildRequestWithAuth("GET");

    return fetch(
      `${environment.originacionUrl}/consumo/solicitudes/${data.Id}/ofertas?Importe=${data.Importe}`,
      request
    )
      .then(errorUnlessOk)
      .then((response) => {
        return response.json();
      })
      .catch((error) => {
        let resultError = parseError(error, "createGetOfertas");
        console.error(resultError);
        throw resultError;
      });
  };

let createPostSolicitudConsumoSeleccionOferta =
  ({ fetch, environment, buildRequestWithAuth, errorUnlessOk, parseError }) =>
  (data) => {
    let request = buildRequestWithAuth("POST", data);

    return fetch(
      `${environment.originacionUrl}/consumo/solicitudes/${data.Id}/ofertaaceptada`,
      request
    )
      .then(errorUnlessOk)
      .then((response) => {
        return response;
      })
      .catch((error) => {
        let resultError = parseError(
          error,
          "createPostSolicitudConsumoSeleccionOferta"
        );
        console.error(resultError);
        throw resultError;
      });
  };

let createUploadTicketConsumo =
  ({ fetch, environment, buildRequestWithAuth, errorUnlessOk, parseError }) =>
  (data) => {
    let request = buildRequestWithAuth("POST", data);

    return fetch(
      `${environment.originacionUrl}/consumo/solicitudes/${data.Id}/docs/ticket`,
      request
    )
      .then(errorUnlessOk)
      .then((response) => {
        return response;
      })
      .catch((error) => {
        let resultError = parseError(error, "createUploadTicketConsumo");
        console.error(resultError);
        throw resultError;
      });
  };

let createInicioFirma =
  ({ fetch, environment, buildRequestWithAuth, errorUnlessOk, parseError }) =>
  (solicitudId, data) => {
    let request = buildRequestWithAuth("POST", {
      Id: solicitudId,
      telefono: data,
    });

    return fetch(
      `${environment.originacionUrl}/consumo/solicitudes/${solicitudId}/docs/firma/inicio`,
      request
    )
      .then(errorUnlessOk)
      .then((response) => {
        return response;
      })
      .catch((error) => {
        let resultError = parseError(error, "createInicioFirma");
        console.error(resultError);
        throw resultError;
      });
  };

let createVerificarFirma =
  ({ fetch, environment, buildRequestWithAuth, errorUnlessOk, parseError }) =>
  (solicitudId) => {
    let request = buildRequestWithAuth("POST", { Id: solicitudId });

    return fetch(
      `${environment.originacionUrl}/consumo/solicitudes/${solicitudId}/docs/solicitud/firma`,
      request
    )
      .then(errorUnlessOk)
      .then((response) => {
        return response.json();
      })
      .catch((error) => {
        let resultError = parseError(error, "createVerificarFirma");
        console.error(resultError);
        throw resultError;
      });
  };

let createReenviarSMS =
  ({ fetch, environment, buildRequestWithAuth, errorUnlessOk, parseError }) =>
  (solicitudId) => {
    let request = buildRequestWithAuth("POST", { Id: solicitudId });

    return fetch(
      `${environment.originacionUrl}/consumo/solicitudes/${solicitudId}/docs/solicitud/firma/invitacion`,
      request
    )
      .then(errorUnlessOk)
      .then((response) => {
        return response;
      })
      .catch((error) => {
        let resultError = parseError(error, "createReenviarSMS");

        throw resultError;
      });
  };

let createGenerarResumenPrestamo =
  ({ fetch, environment, buildRequestWithAuth, errorUnlessOk, parseError }) =>
  (solicitudId) => {
    let request = buildRequestWithAuth("POST", {});

    return fetch(
      `${environment.originacionUrl}/consumo/solicitudes/${solicitudId}/docs/solicitud/resumen`,
      request
    )
      .then(errorUnlessOk)
      .then((response) => {
        return response.json();
      })
      .catch((error) => {
        let resultError = parseError(error, "createGenerarResumenPrestamo");
        throw resultError;
      });
  };

let createGetSolicitudConsumoById =
  ({ fetch, environment, buildRequestWithAuth, errorUnlessOk, parseError }) =>
  (solicitudId) => {
    let request = buildRequestWithAuth("GET");

    return fetch(
      `${environment.originacionUrl}/consumo/solicitudes/${solicitudId}`,
      request
    )
      .then(errorUnlessOk)
      .then((response) => {
        return response.json();
      })
      .catch((error) => {
        let resultError = parseError(error, "createGetSolicitudConsumoById");
        console.error(resultError);
        throw resultError;
      });
  };

export {
  createPostSolicitudConsumoNueva,
  createPostSolicitudConsumoDomicilio,
  createPostDictamenConsumo,
  createGetOfertasConsumo,
  createPostSolicitudConsumoSeleccionOferta,
  createUploadTicketConsumo,
  createInicioFirma,
  createGetSolicitudConsumoById,
  createVerificarFirma,
  createReenviarSMS,
  createGenerarResumenPrestamo,
};
